import GlobalLayout from "components/page/GlobalLayout";
import PricingPage from "components/pricing/PricingPage";
import React from "react";
import Helmet from "react-helmet";

export default function Pricing() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>Rhombus List Pricing - Enterprise Security Cameras</title>
        <meta
          name="description"
          content="Enterprise video surveillance for the modern world. View Rhombus list pricing and request a custom price quote."
        />
      </Helmet>
      <PricingPage />
    </GlobalLayout>
  );
}
